<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import FormAdd from "./add-user.vue";
import FormEdit from "./edit-user.vue";
import FormResetPassword from "./reset-password.vue";
import axios from "axios";
import Swal from "sweetalert2";

import Cookies from "js-cookie";
// import moment from 'moment';
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "User",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    FormAdd,
    FormEdit,
    FormResetPassword
  },
  data() {
    return {
      title: "User",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "User",
          active: true,
        },
      ],
      // variable Page Table
      loadingTable: true,
      dataTable: [],
      pagingTable: [],
      showDataToTable: "",
      showDataFromTable: "",
      totalDataTable: "",
      currentTablePage: "",
      searchDataTable: "",
      showModalAdd: false,
      showFormAdd: false,
      showModalEdit: false,
      showFormEdit: false,
      showModalResetPassword: false,
      showFormResetPassword: false,

      table_data: [],
      options_status: ["ENABLE", "DISABLE"],
      selected: [],
      search: "",

      idData: 0,
    };
  },
  mounted() {
    this.getDataTable();
  },
  methods: {
    getDataTable() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/master/user?search=" +
          self.search
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.table_data = response_data.list_data.data;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    formAddData() {
      let self = this;
      self.showModalAdd = false;
      self.showFormAdd = false;
      // config untuk re render component
      self.$nextTick(() => {
        self.showModalAdd = true;
        self.showFormAdd = true;
      });
    },
    formEditData(id) {
      let self = this;
      self.showModalEdit = false;
      self.showFormEdit = false;
      // config untuk re render component
      self.$nextTick(() => {
        self.showModalEdit = true;
        self.showFormEdit = true;
        self.idData = id;
      });
    },
    formResetPassword(id) {
      let self = this;
      self.showModalResetPassword = false;
      self.showFormResetPassword = false;
      // config untuk re render component
      self.$nextTick(() => {
        self.showModalResetPassword = true;
        self.showFormResetPassword = true;
        self.idData = id;
      });
    },
    refreshTable(type) {
      let self = this;
      if (type == "add") {
        self.showModalAdd = false;
        self.showFormAdd = false;
      } else if (type == "update") {
        self.showModalEdit = false;
        self.showFormEdit = false;
      } else {
        self.showModalResetPassword = false;
        self.showFormResetPassword = false;
      }
      self.getDataTable();
    },
    hapusData(id) {
      console.log(id);
      var alert_text = "Apakah Anda yakin?";
      Swal.fire({
        title: "Warning ?",
        text: alert_text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
      }).then((result) => {
        if (result.isConfirmed) {
          let self = this;
          Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var FormData = require("form-data");
          var data = new FormData();
          data.append("id", id);

          var config = {
            method: "post",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "/api/master/user/hapus",
            data: data,
          };
          axios(config)
            .then(function (response) {
              console.log(response);
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Anda akan diarahkan ke halaman master user segera",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  Swal.close();
                  self.getDataTable();
                }
              });
            })
            .catch((e) => {
              this.axiosCatchError = e.response.data.data;
              Swal.close();
            });
        } else {
          Swal.close();
        }
      });
    },

    login(is_login, username, password) {
      if (is_login == 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: 'User tidak memiliki akses login',
        });
      } else {
        var alert_text = "Apakah anda yakin ingin login sebagai user ini?";
        Swal.fire({
          title: "Warning ?",
          text: alert_text,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
        }).then((result) => {
          if (result.isConfirmed) {
            let self = this;
            Swal.fire({
              title: '<i class="fas fa-spinner fa-spin"></i>',
              text: "Loading...",
              showConfirmButton: false,
            });
            var FormData = require("form-data");
            var data = new FormData();
            data.append("username", username);
            data.append("password_form_frontend", password);

            var config = {
              method: "post",
              url:
                process.env.VUE_APP_BACKEND_URL_VERSION + "/api/account/act_login",
              data: data,
            };
            axios(config).then((response) => {
              var data_login = response.data;
              // check status login
              if (data_login.code != 200) {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Akun tidak ditemukan!",
                });
              } else {

                // session configuration
                Cookies.set('session', JSON.stringify(data_login.user), {
                  expires: 1,
                  secure: true, // Ensure cookie is sent over HTTPS
                  sameSite: 'Lax' // Adjust according to your needs
                });
                // session configuration
                Cookies.set('session_username', data_login.user.username, {
                  expires: 1,
                  secure: true, // Ensure cookie is sent over HTTPS
                  sameSite: 'Lax' // Adjust according to your needs
                });
                // session configuration
                Cookies.set('session_nama', data_login.user.name, {
                  expires: 1,
                  secure: true, // Ensure cookie is sent over HTTPS
                  sameSite: 'Lax' // Adjust according to your needs
                });
                // session configuration
                Cookies.set('session_id', data_login.user.id, {
                  expires: 1,
                  secure: true, // Ensure cookie is sent over HTTPS
                  sameSite: 'Lax' // Adjust according to your needs
                });
                // session configuration
                Cookies.set('session_role_id', data_login.user.role_id, {
                  expires: 1,
                  secure: true, // Ensure cookie is sent over HTTPS
                  sameSite: 'Lax' // Adjust according to your needs
                });
                Cookies.set('session_role_id', JSON.stringify(data_login.user.role_id), {
                  expires: 1,
                  secure: true, // Ensure cookie is sent over HTTPS
                  sameSite: 'Lax' // Adjust according to your needs
                });
                localStorage.reload_already = "no";
                localStorage.dateLogin = Date.now();

                let timerInterval;
                Swal.fire({
                  icon: "success",
                  title: "Berhasil",
                  text: "Anda akan diarahkan ke halaman dashboard segera",
                  timer: 2000,
                  timerProgressBar: true,
                  showCancelButton: false,
                  showConfirmButton: false,
                  willClose: () => {
                    clearInterval(timerInterval);
                  },
                }).then((result) => {
                  /* Read more about handling dismissals below */

                  // return false;
                  if (result.dismiss === Swal.DismissReason.timer) {
                    Swal.close();
                    self.$router.push({ name: "default" });
                  }
                });
              }
            });
          } else {
            Swal.close();
          }
        });
      }
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
    />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-9">
                    <button
                      type="button"
                      v-on:click="formAddData"
                      class="btn btn-primary btn-sm m-1"
                    >
                      <i class="fa fa-plus"></i> Tambah User
                    </button>
                  </div>
                  <div class="col-md-3">
                    <input
                      type="text"
                      class="form-control"
                      id="searchTable"
                      v-model="search"
                      @input="getDataTable()"
                      placeholder="Cari Data ..."
                    />
                  </div>
                </div>
                <br />
              </div>
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                    <thead
                      class="text-center text-white"
                      style="background-color: #132d4a"
                    >
                      <tr class="text-center">
                        <th style="width: 20px">No</th>
                        <th style="width: 100px">Username</th>
                        <th style="width: 100px">Name</th>
                        <th style="width: 100px">Role</th>
                        <th style="width: 100px">Jabatan</th>
                        <th style="width: 100px">Departemen</th>
                        <th style="width: 100px">Akses Login</th>
                        <th style="width: 100px">Description</th>
                        <th style="width: 400px">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loadingTable">
                        <td colspan="9">
                          <i class="fas fa-spinner fa-spin"></i> Loading...
                        </td>
                      </tr>
                      <tr v-else-if="table_data == ''">
                        <td
                          class="text-center"
                          colspan="6"
                        >
                          Data Tidak Tersedia
                        </td>
                      </tr>
                      <tr
                        v-else
                        v-for="(row_data, key_data) in table_data"
                        :key="key_data"
                      >
                        <td class="text-center">
                          {{ key_data + 1 }}
                        </td>
                        <td>
                          {{ row_data.username }}
                        </td>
                        <td>
                          {{ row_data.name }}
                        </td>
                        <td class="text-center">
                          {{ row_data.role }}
                        </td>
                        <td class="text-center">
                          {{ row_data.jabatan }}
                        </td>
                        <td class="text-center">
                          {{ row_data.nama_departement }}
                        </td>
                        <td class="text-center">
                          <div v-if="row_data.is_login == 1">
                            Ya
                          </div>
                          <div v-else>
                            Tidak
                          </div>
                        </td>
                        <td class="text-center">
                          {{ row_data.desc }}
                        </td>
                        <td class="text-center">
                          <div class="btn-group">
                            <button
                              v-on:click="formEditData(row_data.id)"
                              class="btn btn-sm btn-info"
                            >
                              <i class="fa fa-edit"></i> Edit
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm btn-danger"
                              v-on:click="hapusData(row_data.id)"
                            >
                              <i class="fa fa-trash"></i> Hapus
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm btn-warning"
                              v-on:click="login(row_data.is_login, row_data.username, row_data.password)"
                            >
                              <i class="fa fa-paper-plane"></i> Login
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm btn-primary"
                              v-on:click="formResetPassword(row_data.id)"
                            >
                              <i class="fa fa-key"></i> Reset Password
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Popup Form Tambah Data -->
    <b-modal
      v-model="showModalAdd"
      title="Add New User"
      title-class="text-black font-18"
      body-class="p-3"
      size="md"
      hide-footer
    >
      <form-add
        v-if="showFormAdd"
        @refresh-table="refreshTable"
      ></form-add>
    </b-modal>
    <!-- Popup Form Edit Tambah Data -->
    <b-modal
      v-model="showModalEdit"
      title="Edit Data User"
      title-class="text-black font-18"
      body-class="p-3"
      size="md"
      hide-footer
    >
      <form-edit
        v-if="showFormEdit"
        :idData="idData"
        @refresh-table="refreshTable"
      ></form-edit>
    </b-modal>
    <!-- Popup Form Reset Password -->
    <b-modal
      v-model="showModalResetPassword"
      title="Reset Password"
      title-class="text-black font-18"
      body-class="p-3"
      size="md"
      hide-footer
    >
      <form-reset-password
        v-if="showFormResetPassword"
        :idData="idData"
        @refresh-table="refreshTable"
      ></form-reset-password>
    </b-modal>
  </Layout>
</template>
