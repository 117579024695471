<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "form-add",
  props: [],
  data() {
    return {
      // Catch Error Axios
      axiosCatchError: null,
      // variable Page
      master_role: [],
      master_jabatan: [],
      master_departement: [],
      akses_login: ["Ya", "Tidak"],
      role_selected: "",
      jabatan_selected: "",
      departemen_selected: "",
      akses_login_selected: "",
      showJabatan: false,
      showDepartemen: false,
      status: 'ENABLE'
    };
  },
  mounted() {
    this.getRole();
  },
  methods: {
    getRole() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(process.env.VUE_APP_BACKEND_URL_VERSION + "/api/master/role")
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.master_role = response_data.list_data.role;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    roleSelected(){
      let self = this;
      var id_role = self.role_selected?.id;
      if(id_role == 20 || id_role == 21 || id_role == 22){
        self.showJabatan = true;
        self.showDepartemen = true;
        
        axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/master/departemen?status=" +
            self.status
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.master_departement = response_data.list_data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });

        axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/master/jabatan?status=" +
            self.status
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.master_jabatan = response_data.list_data.jabatan;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
      }else{
        self.showJabatan = false;
        self.showDepartemen = false;
      }
    },

    StoreData() {
      let self = this;
      var role_id = self.role_selected?.id;
      if(role_id){
        role_id = self.role_selected?.id;
      }else{
        role_id = '';
      }
      var jabatan_id = self.jabatan_selected?.id;
      if(jabatan_id){
        jabatan_id = self.jabatan_selected?.id;
      }else{
        jabatan_id = '';
      }

      var departemen_id = self.departemen_selected?.id;
      if(departemen_id){
        departemen_id = self.departemen_selected?.id;
      }else{
        departemen_id = '';
      }

      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var FormData = require("form-data");
      var data = new FormData();
      data.append("username", self.username);
      data.append("email", self.email);
      data.append("password", self.password);
      data.append("name", self.name);
      data.append("role_id", role_id);
      data.append("jabatan_id", jabatan_id);
      data.append("departemen_id", departemen_id);
      data.append("akses_login", self.akses_login_selected);
      data.append("description", self.description);

      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION + "/api/master/user/store",
        data: data,
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data; 
          if(response_data.code != 200){
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: response_data.message,
            });
          }else{
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman master user segera",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                self.$emit("refresh-table", "add");
              }
            });
          }
        })
        .catch((e) => {
          this.axiosCatchError = e.response.data.data;
          Swal.close();
          self.$router.push({ name: "all-user" });
        });
    },
  },
};
</script>

<template>
  <b-form class="p-2" @submit.prevent="StoreData">
    <small>Username</small>
    <b-form-input type="text" v-model="username"></b-form-input>

    <small>Email</small>
    <b-form-input type="text" v-model="email"></b-form-input>

    <small>Password</small>
    <b-form-input type="password" v-model="password"></b-form-input>

    <small>Name</small>
    <b-form-input type="text" v-model="name"></b-form-input>

    <small>Role</small>
    <v-select placeholder="Pilih Role" :options="master_role" label="role" v-model="role_selected" @update:modelValue="roleSelected"></v-select>

    <div v-if="showDepartemen">
      <small>Departemen</small>
      <v-select placeholder="Pilih Departemen" :options="master_departement" label="nama" v-model="departemen_selected"></v-select>
    </div>

    <div v-if="showJabatan">
      <small>Jabatan</small>
      <v-select placeholder="Pilih Jabatan" :options="master_jabatan" label="jabatan" v-model="jabatan_selected"></v-select>
    </div>

    <small>Akses Login</small>
    <v-select placeholder="Pilih Akses Login" :options="akses_login" v-model="akses_login_selected"></v-select>

    <small>Description</small>
    <textarea class="form-control" v-model="description"></textarea>

    <div class="row mt-4">
      <div class="col-lg-6">&nbsp;</div>
      <div class="col-lg-6">
        <div class="text-end">
          <b-button type="submit" variant="primary" class="m-1"
            ><i class="fa fa-save"></i> Simpan</b-button
          >
        </div>
      </div>
    </div>
  </b-form>
</template>
