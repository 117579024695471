<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "form-add",
  props: ["idData"],
  data() {
    return {
      // Catch Error Axios
      axiosCatchError: null,
      // variable Page
      master_role: [],
      akses_login: ["Ya", "Tidak"],
      role_selected: "",
      dataid: this.idData,
    };
  },
  mounted() {
  },
  methods: {
    StoreData() {
      let self = this;

      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var FormData = require("form-data");
      var data = new FormData();
      data.append("id", self.dataid);
      data.append("password", self.password);
      data.append("confirm_password", self.confirm_password);

      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION + "/api/master/user/resetpassword",
        data: data,
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data; 
          if(response_data.code != 200){
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: response_data.message,
            });
          }else{
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman master user segera",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                self.$emit("refresh-table", "reset");
              }
            });
          }
        })
        .catch((e) => {
          this.axiosCatchError = e.response.data.data;
          Swal.close();
          self.$router.push({ name: "all-user" });
        });
    },
  },
};
</script>

<template>
  <b-form class="p-2" @submit.prevent="StoreData">
    <small>Password</small>
    <b-form-input type="password" v-model="password"></b-form-input>

    <small>Confirm Password</small>
    <b-form-input type="password" v-model="confirm_password"></b-form-input>

    <div class="row mt-4">
      <div class="col-lg-6">&nbsp;</div>
      <div class="col-lg-6">
        <div class="text-end">
          <b-button type="submit" variant="primary" class="m-1"
            ><i class="fa fa-save"></i> Simpan</b-button
          >
        </div>
      </div>
    </div>
  </b-form>
</template>
